 :root {
    /* Set the deafult values */
    --selected-primary-color: var(--light-primary-color);
    --selected-disabled-color: var(--light-disabled-color);
    
    /* Set the 'light' theme */
    --light-primary-color: #007bff;
    --light-disabled-color: #6c757d;
  
    /* Set the 'dark' theme */
    --dark-primary-color: #000000;
    --dark-disabled-color: #4a4a4b;

    --themecolor: #177913;
    --desktopCardHeight: 480px;
    --mobileCardHeight: 370px; 
    /*shakers 480px  --- debanjana 550px*/
} 

*{
    font-family: roboto;
}
.row{
    margin-left: 0;
    margin-right: 0;
}
p{
    margin-bottom: 0;
}
input{
    outline:none !important;
}
button{
    outline:none !important;
}
.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #000;
    background-color: #d7d7d7;
}
body{
    font-family: Roboto;
    background-color: #f5f5f5;
}.home-menuli{
    display: inline-block;
    vertical-align: top;
}
.bord-none{
    border:none;
}
.home-menu {
    text-align: right;
    background: #177913;
    padding: 8px 15px;
    position: fixed;
    top:0;
    width:100%;
    z-index: 9;
}
.cart{
    width: 100%;
}
.cart-icon{
    /* margin-left: 28px; */
    position: relative;
    /* float: right; */
}
.home-menuli{
    padding: 0 8px;
}
.home-menuli a{
    color: #fff;
}
.pinmodal-wrapper{
    left: 0;
}
.menu-items{
    left: 0;
}
.hideclassmain {
    display: none;
}
.hide-error {
    display: none;
}
.company-phoneno{
    line-height: 14px;
    width: 90px;
    display: inline-block;
}
.tagline{
    font-size: 14px;
    margin-top: 10px;
    color: var(--themecolor);
    font-weight: 500;
}
.mob-category{
    display: none;
}
.menu-items111 {
    position: fixed;
    top: 237px;
    width: 100%;
    left: 0;
    background: #ffffff;
    box-shadow:0 0 10px #dadada;
}
.nav-justified .nav-item{
    flex-basis: auto;
}
header{
    padding: 0px 0px;
    /* box-shadow: 0px 8px 18px rgba(0,0,0,0.033); */
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 9;
    -webkit-animation: slideUp 300ms linear;
    animation: slideUp 300ms linear;
    background-color: #fff;
    border-bottom:1px solid #dadada;
}
.topsection-header{
    padding: 10px 0;
    background: #fff;
    padding-bottom: 0;
}
.topsection-category{
    padding: 10px 0;
    background: #fff;
    /* border-bottom: 2px solid #177913; */
    /* box-shadow: 1px -3px 7px #f1eded; */
}
.brand .logo{
    cursor: pointer;
    /* width:150px; */
    width:130px;
}
 .show-pin-err {
    clear: both;
    display: block;
    font-size: 11px;
    color: red;
    width: 260px;
    margin-left: -80px;
    margin-top: 5px;
}
.hide-pin-err{
    display: none;
}
.pin-code img{
    width: 25px;;
}
.zipname{
    /* font-size: 14px; */
    /* color: var(--themecolor); */
    color:#000;
    cursor: pointer;
    font-weight: 700;
}

.cursor {
    cursor: pointer;
}

.search-form {
    display: flex;
    display: -webkit-flex;
    margin-bottom: 0;
}
/* .search-form .search-keyword {
    height: 36px;
    border: 1px solid var(--themecolor);
    padding: 0 8px;
    border-radius: 2px 0 0 2px;
    width: 100% !important;
} */
.search-form .search-keyword {
    height: 36px;
    border: 1px solid var(--themecolor);
    padding: 0 15px;
    border-radius: 18px;
    width: 100% !important;
    font-size: 14px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.search-form .search-button {
    border-radius: 0 2px 2px 0;
    width: 70px;
    background: var(--themecolor);
    border:none;
    border-radius: 18px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    font-size: 16px;
}
.mobile-search{
    display: none;
}
.map-icon{
    font-size: 25px;
    color: #4b4b4b;
}
.cart-info {
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
}
.icon-image {
    width: 20px;
}
.branding-section {
    /* position: fixed; */
    width: 100%;
    background: white;
    /* z-index: 9;
    top: 147px; */
    padding: 10px 0;
    /* box-shadow: 0 0 5px #b7b7b7; */
    box-shadow: 0 0 2px #e8e7e7;
    /* border-bottom: 2px solid #177913; */
    
}
/* .full-Pagesection{
    padding: 180px 0 50px 0;
} */
.full-Pagesection{
    padding: 125px 0 50px 0;
}
.branding-div{
    display: inline-block;
    vertical-align: top;
}
.branding-div-first img {
    width: 65px;
}
.brands-name {
    font-size: 16px;
    text-transform: uppercase;
    color: #333;
    margin: 20px 0 20px 0;
    font-weight: 600;
}
.branding-div-third img {
    width: 65px;
}
.branding-div-haringhata img {
    width: 95px;
}
.branding-div-gogras img {
    width: 120px;
}
.addressbrands-second {
    font-size: 14px !important;
    color: #333;
}
.branding-div-sec4 p {
    font-weight: 600;
    margin-bottom: 0;
    text-align: center;
}
footer{
    position:fixed;
    width:100%;
    left: 0;
    bottom: 0;
    background-color: #ddd;
    z-index: 9;
}
footer p{
    margin:0;
    text-align: center;
    font-size: 12px;
    color: #999;
    padding: 15px 0;
}
.bord-right1px{
    border-right: 1px solid #dadada;
}
.bord-bot-5{
    border-bottom: 5px solid #efefef;
}
.categories-section .nav-link {
    border-radius: 0.25rem;
    /* background: #EFEFEF; */
    background: none;
    margin: 0px 3px;
    font-size: 13px;
    padding: 10px 0;
    color: #333;

    /* min-height: 35px; */
    /* min-height: 78px; */
    border-radius: 0;
    text-align: center;
}
.products-wrapper{
    margin-top: 25px;;
}
.single-amt {
    color: #757575;
    font-size: 14px;
    font-weight: 600;
    }
    .lh-16{
        line-height: 16px ;
    }
.product-box {
    background: #fff;
    padding: 10px 10px 0 10px;
    position: relative;
    min-height: var(--desktopCardHeight);
    margin-bottom: 20px;
    box-shadow: 0 0 2px #dadada;
}
.product-box:hover{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}
.product-image {
    position: relative;
    text-align: center;
    border:1px solid #dadada;  
}

.product-img{
    width: 100%;
    cursor: pointer;
    border:1px solid #dadada; 
}
.l-0{
    left:0;
}
.b-0{
    bottom: 0
}
.b-35{
    bottom:35px;
}
.b-40{
    bottom:40px;
}
.brand-iconmaindiv {
    width: 35px;
    top: 6px;
    left: 6px;
    position: absolute;
    z-index: 0;
    background: #ffffff;
    height: 35px;
    /* box-shadow: 1px 1px 5px 0px #bcbcbc;
    border-top-right-radius: 2px; */
    border-bottom-right-radius: 2px;
}
.brand-iconmaindiv .brand-img{
    width: 100%;
}
.product-action {
    padding: 0px;
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
}
.addtocart-btn {
    height: 34px;
    border-radius: 34px;
    text-align: center;
    color: #fff;
    background: var(--themecolor);
    border: 0;
    font-weight: 400;
    padding: 0 16px;
    font-family: inherit;
    font-size: 14px;
    cursor: pointer;
    width: auto;
}
.active-variant-btn {
    border: 1px solid #868686;
    color: #fff;
    background: var(--themecolor);
}
.product-action button{
    height: 34px;
    border-radius: 0px;
    text-align: center;
    color: #fff;
    background: var(--themecolor);
    border: 0;
    font-weight: 400;
    padding: 0 16px;
    font-family: inherit;
    font-size: 14px;
    cursor: pointer;
    width: 130px;
    border-radius: 36px;
    margin-bottom: 15px;
}
.product-name {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 5px;
    /* font-weight: 600; */
    color: #656565;
}
.product-name-home {
    /* height: 42px; */
    overflow: hidden;
    margin-bottom: 10px;
    text-align: left;
    margin-top: 15px;
}
.jc-flc{
    justify-content: center;
    flex-direction: column;
}
.product-box .stepperc-input{
    width:100%;
}
.product-box .product-name{
    font-size: 14px;
    font-weight: 600;
}
.bg-light-modal{
    background-color: #f4f4f4;
}
.hc-modal{
    height:calc(100vh - 145px);
    overflow-x: hidden;
    overflow-y: auto;
}
.product-name-local {
    height: 42px;
    overflow: hidden;
    margin-bottom: 10px;
    text-align: center;
    margin-top: 15px;
    display: none;
}
.product-price {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 15px;
    text-align: center;
}
.product-name-price{
    position: absolute;
    width: 100%;
    bottom: 45px;
    margin-bottom: 0;
    left: 0;
}
.stepperc-input{
    width:auto;
    text-align: center;
    display: inline-block !important;
    position: relative;
    top:-15px;
}
.decrement{
    display: inline-block;
    vertical-align: top;
    height: 28px;
    padding: 2px 10px;
    border:1px solid #333;
    width: 30px;
    border-right: none;
    cursor: pointer;
    border-top-left-radius: 28px;
    border-bottom-left-radius: 28px;
}
.decrement img {
    width: 12px;
    position: relative;
    top: -2px;
}
.quantity{
    width:45px;
    margin: 0 auto;
    border-radius: 0;
    border-width: 1px;
    text-align: center;
    border-color:#000;
    height: 28px;
}
.itemlistinner{
    margin-bottom: 0px;
}
.increment{
    display: inline-block;
    vertical-align: top;
    height: 28px;
    padding: 2px 10px;
    border:1px solid #333;
    width: 30px;
    border-left: none;
    cursor: pointer;
    border-top-right-radius: 28px;
    border-bottom-right-radius: 28px;
}
/* .cart-count {
    display: block;
    position: absolute;
    background: #fc7710;
    height: 24px;
    line-height: 24px;
    text-align: center;
    font-size: 12px;
    color: #fff;
    width: 24px;
    border-radius: 50%;
    top: -6px;
    right: -9px;
} */
.lh-15{
    line-height:15px;
}
.cart-count {
    display: block;
    position: absolute;
    background: #fc7710;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-size: 10px;
    color: #fff;
    width: 20px;
    border-radius: 50%;
    top: 20px;
    left: 20px;
}
.itemlist{
    margin-top: 10px;
}
.cart-brand-iconmaindiv{
    display: block;
    position: relative;
}
.product-image-cart{
    width:100%;
}
.onr-rigt{
    text-align: left;
}
.cart-item {
    margin-bottom: 2px;
    background: #fff;
    padding: 5px 0;
}
.chk-out-button-mobal button{
    width: 100%;
    background-color: #fc7710;
    border: none;
    box-shadow: none;
    color: #fff;
    padding: 7px 10px;
    text-align: left;
    border-radius: 2px;
}

.checkout-disable{
    cursor: no-drop;
    opacity: 0.7;
}

.checkout-count .checkoutstepperc-input {
    text-align: left;
    top:auto;
}
.cart-items{
    background-color: #fff;
    padding-top:0px;
    padding-bottom:0px;
    border:1px solid #e6e3e3;
    margin-bottom: 70px;
}
.inblkclass-top img{
    width:25px;
}
.product-remove-top{
    position: absolute;
    right: 10px;
    top: 5px;
    font-size: 13px;
    color: #fff !important;
    background: red;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    padding: 0px 6px;
    text-decoration: none;
}
.total-amnt-lbl {
    font-size: 24px;
    font-weight: 600;
}
.total-cart-amount{
    position: relative;
    background-color: #fff;
    box-shadow: 2px 1px 8px 1px #d8d8d8;
    padding: 0 15px;
    text-align: right;
}
.empty-cart{
    text-align: center;
}
.inblkclass-top {
    position: absolute;
    top: 7px;
    left: 7px;
}
.pos-fixedclass{
    position:fixed;
    width:100%;
    bottom: 48px;
    z-index: 9;
}
.chk-out-button{
    background: #fff;
    box-shadow: 0 0 5px #afaeae;
    padding: 10px 15px;
    text-align: right;
}
.chk-out-button button {
    background: #fc7710;
    min-width: 200px;
    padding:5px;
    border:none;
    box-shadow: none;
    color: #fff;
    outline: none !important;
}
.product-links-anchor{
    min-width: 200px;
    display: inline-block;
    padding: 3px;
    text-align: center;
    border: 2px solid #fc7610;
    color: #fc7610 !important;
    text-decoration: none !important;
}
.chkout-main-imgpanel{
    position: relative;
}
.cart-brand-img{
    position: absolute;
    width: 15px;
    z-index: 001;
    box-shadow: 1px 1px 5px #b5b3b3;
    background: #fff;
    left: 1px;
    top: 1px;
}
.stop-scrolling {
    height: 100%;
    overflow: hidden;
  }
  .product-details-maindiv {
    background: #e8e8e8;
    padding: 15px 0;
    /* height: 100vh; */
    margin-top: 5px;
    margin-bottom: 60px;
}
.br-0{
    border-radius: 0;
}
.product-description-mainname{
    font-size: 20px;
    font-weight: 600;
}
.quick-view-image{
    position: relative;
    border:1px solid #dadada;
}
.thumbs-wrapper {
    margin: 20px 0 !important;
}
.thumbs{
    padding-left: 0 !important;
}
.sl-localname{
    font-size: 16px;;
}
.primary-button{
    background: #fc7710;
    min-width: 200px;
    padding: 5px;
    border: none;
    box-shadow: none;
    color: #fff;
    outline: none !important;
}
.product-price-product-details {
    font-size: 25px;
    color: var(--themecolor);
}
.bot-autofd-div{
    bottom: auto;
    top:0;
}
.bx-shadow-withbord{
    box-shadow: none;
    border-bottom: 1px solid #efefef;
}
.control-dots .dot {
    box-shadow: 0 0 3px #fc7610 !important;
    background: #fc7610 !important;
    width: 10px !important;
    height: 10px !important;
}
.quick-view-details .stepperc-input{
    text-align: left !important;
    top:0 !important;
}
.final-ord-list{
    padding: 5px 0;
    border-bottom: 1px solid #dadada;
}
.final-ord-list:nth-child(odd){
    background-color: #f2f2f2;
}
.fs-13-f-n{
    font-size: 13px;
    font-weight: normal;
}
.fs-14{
    font-size: 14px;
}
.font-12{
    font-size: 12px;
}
.bgf2f2f2{
    background-color: #f2f2f2;
}
.bord-bot-theme{
    border-bottom: 2px solid var(--themecolor); ;
}
.mob-categorybutton{
    height: 36px;
    border-radius: 0px;
    text-align: center;
    color: #fff;
    background: var(--themecolor);
    border: 0;
    font-weight: 400;
    padding: 0 12px;
    font-size: inherit;
    cursor: pointer;
    border-radius: 20px;
}
.top-50-martop{
    margin-top: 50px;
}
.variant-right {
    padding: 5px 0px;
    border: 1px solid #dadada;
    border-left: 5px solid var(--themecolor);
    margin-bottom: 8px;
}
.variant-right-active{
    border-left: 5px solid var(--themecolor);
}
.close-button {
    padding: 2px 10px;
    border:none
}
.close-button .close{
    color:red !important;
    opacity: 1 !important;
}
.centered-div{
    display: flex;
    height: 100%;
    justify-content: center;
    flex-direction: column;
}
.centered-div .stepperc-input{
    top:0
}
.variant-item-promo-price {
    color: #868686;
    font-size: 15px;
}
.variant-select-btn-control button{
    margin-right: 10px;
    border:1px solid #868686;
}
.grand-total{
    background: #6ac688;
    padding: 6px 5px;
    color: #fff;
}
.f-size{
    font-size: 15px;
}
.cl-btn-white .close{
    color:#fff;
    opacity: 1 !important;
}
.f-size22{
    font-size: 22px;
}
.number-before-check{
    width: 22px;
    height: 22px;
    margin-bottom: 0;
    border-radius: 50%;
    border: 2px solid #29a745;
    font-size: 12px;
    padding: 0 6px;
    font-weight: 600;
    color: #29a745;
    vertical-align: top;
}
/* Disable number type input arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.hide-error{
    color:red;
    font-size: 14px;
}
.show-error{
    color:red;
    font-size: 14px;
}
.cart-modals .modal-dialog{
    margin:0;
    float: right;
}
.cart-modals .modal-content{
    border-radius: 0;
    border:none;
}
.cart-modals .modal-header{
    background-color: #333;
    padding: 10px 15px;
    border-radius: 0;
}
.cart-modals .modal-title{
    color:#fff;
    font-size: 17px;
}
.cart-modals .close {
    opacity: 1;
    color: #fff;
    font-weight: 100;
}
button[disabled]{
    color:#fff;
}
.z-index1{
    z-index: 1;
}
.percent-offer{
    right:0;
    top:5px;
}
.percent-offer{
    width: 70px;
    height: 18px;
    position: relative;
    background: red;
    color:#fff;
    font-size: 12px;
    text-align: center;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
  }
  .label-offer {
    right: 0;
    top: 25px;
    font-size: 12px !important;
    font-weight: normal;
    border-radius: 0;
    background: red;
    min-width: 90px;
}
.bg-red{
    background: red;;
}
.font-size14px{
    font-size:14px;
}
.font12px{
    font-size: 12px;
}
.carousel-status{
    display: none;
}
.arrow-style{
    font-size:25px;
    color: #000 !important;
    cursor: pointer;
}
/* .arrow-style:hover{
    color:#000 !important;
} */
/* -------------------------------------------------Responsive css Start--------------------------------- */
@media (max-width: 767px){
.brand .logo {
    width: 80px;
    margin: 2px 0;
}
.product-box{
    /* for mobile version */
    min-height: var(--mobileCardHeight);
}
/* .pos-rel-mobile{
    position: unset !important;
} */
.show-pin-err{
    width: 200px;
    margin-left: -70px;
    margin-top: 0;
}
.mob-location-custom {
    position: fixed;
    right: 80px;
}
.ml-15inob{
    margin-left: 15px;
}
.mb-10-mob{
    margin-bottom: 10px;
}
.pr-10-mob{
    padding-right:10px !important;
}
.pl-0-mob{
    padding-left: 0;
}
.p-0-mob{
    padding: 0;
}
.pin-code label{
    margin-bottom: 0;
}
.show-pin-err{
    font-size: 9px;
}
.hide-pin-err{
    display: none;
}
.tagline{
    display: none;
}
.cart-info{
    display: none;
}
.cart-count{
    display: block;
}
.mobile-bord-bottom{
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
}
.mob-category{
    display: block;
}
.cart-icon{
    position: fixed;
    right: 15px;
    top: 10px;
}
.brands-name{
    display: none;
}
.branding-div-sec4 {
    display: none;
}
.mob-pad{
    padding:7px 15px 7px 15px;
    border-bottom: 1px solid #c7c5c5;
}
.topsection-category{
    padding:0;
}
.full-Pagesection {
    padding: 116px 0 50px 0;
}
.p-0-xs{
    padding:0;
}
.bord-right1px{
    border:none;
}
.categories-section .nav-item{
    flex:none;
    width: 100%;
    border-bottom: 1px solid #e0e0e0;
    text-align: left;
    margin-bottom: 0px;
    text-align: left;
}
.hidd-xs{
    display: none;
}
.icon-image {
    width: 25px;
    margin-right: 10px;
}
.hc-modal {
    height: calc(100vh - 210px);
}
.categories-section .nav-link{
    padding: 7px 15px;
    min-height: auto;
    /* background: #eaeaea; */
    background: #fff;
    margin: 0;
    font-size: 14px;
    text-align: left !important;
}
.categories-section{
    height: calc(100vh - 150px);
    overflow-x: auto;
    overflow-y: auto;
}
.branding-div-first img{
    width: 45px;
}
.branding-div-third img{
    width: 45px;
}
.branding-div.branding-div-haringhata.pl-5{
    padding:0 !important;
}
.branding-div-haringhata img {
    width: 60px;
}
.branding-div-gogras img {
    width: 60px;
}
.pr-0-mob {
    padding-right: 0;
}
.pl-0-mob {
    padding-left: 0;
}
.p-0-mob{
    padding: 0;
}

/* .product-box{
    min-height: 370px;
} */

.product-name{
    font-size: 12px;
}
.product-details-maindiv{
    height: auto;
}
.thumbs{
    margin-bottom: 0;
}
.thumbs-wrapper {
    margin: 5px 0 !important;
}
.product-description-mainname{
    font-size: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.product-price-product-details {
    font-size: 25px;
    margin: 15px 0 !important;
}
.details-description-section p {
    margin-top: 10px !important;
    font-size: 14px;
}
.itemlist {
    margin-top: 5px;
}
.lh-mob-20{
    line-height: 20px;
}
.total-amnt-lbl {
    width: 100%;
    margin-bottom: 5px;
    font-size: 20px;
}
.product-links-anchor{
    min-width:130px;
}
.chk-out-button button{
    min-width: 130px;
}
.chk-out-button{
    padding: 6px 15px;
}
.pos-fixedclass{
    z-index: 2;
}
.cart-items{
    margin-bottom: 85px;
}
.itemlistinner {
    margin-bottom: 10px;
}
.vl-top-d-inline-mob{
    display: inline-block;
    vertical-align: top;
}
.mob-wid-30{
    width:30%;
}
.mob-wid-65{
    width:65%;
    padding: 0 10px; 
}
.mt-10-mob{
    margin-top: 10px;;
}
.centered-div .addtocart-btn{
    padding: 0 5px;
    font-size: 11px;
}
.decrement{
    width:28px;
    padding: 2px 12px;
    height: 28px;
}
.decrement img{
    left:-3px;
}
.increment{
    width:28px;
    padding: 2px 8px;
    height: 28px;
}
.stepperc-input{
    margin-top:15px;
}
.quantity{
    width: 25px;
    height: 28px;
    font-size: 14px;
}
.mob-hide{
    display:none !important;
}
.quick-view-image{
    background: #fff !important;
    width:40%;
    margin:0 auto;
}
.carousel .slide{
    background: transparent !important;
    min-height: 160px;;
}
.control-dots .dot {
    box-shadow: 0 0 3px #000000 !important;
    background: #000000 !important;
    width: 8px !important;
    height: 8px !important;
    margin: 0 5px !important;
}
.carousel .control-prev.control-arrow:before{
    border-right:8px solid #000 !important;
}
.carousel .control-next.control-arrow:before {
    border-left: 8px solid #000 !important;
}
/* .main-imgproduct-image{
    width:40% !important;
} */
.carousel .thumb img {
    vertical-align: top;
    width:100% !important;
}
}
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .p-0-tab{
        padding: 0 !important;
    }
    .pl-0-tab{
        padding-left: 0 !important;
    }
    .pr-0-tab{
        padding-right: 0 !important;
    }
    .brand .logo {
        width: 100%;
    }
    .pin-code label{
        margin-bottom: 0;
    }
    .pin-code img{
        width: 20px;
    }
    .show-pin-err{
        font-size: 11px;
        width: 245px;
        margin-left: -100PX;
        margin-top: 5px;
    }
    .tagline {
        font-size: 11px;
        margin-top: 4px;
    }
    .cart-info{
        font-size: 14px;
    }
    .tada {
        /* width:22px; */
    }
    .cart-icon {
        margin-left: 0;
        /* position: absolute; */
        right: -25px;
        top: 0;
    }
    .categories-section .nav-link{
        margin: 0 2px;
        padding: 10px 5px;
    }
    .topsection-category{
        padding: 5px 0;
    }
    .full-Pagesection {
        padding: 125px 0 50px 0;
    }
    .branding-div-first img {
        width: 45px;
    }
    .branding-div-third img{
        width: 45px;
    }
    .brands-name{
        margin:12px 0;
        font-size: 14px;
    }
    .addressbrands-second{
        font-size: 12px !important;
    }
    .p-0-5-tab{
        padding: 0 5px;
    }
    .centered-div .addtocart-btn{
        padding: 0 5px;
        font-size: 11px;
    }
    .decrement{
        width:20px;
        padding: 2px 6px;
    }
    .decrement img{
        left:-5px;
    }
    .increment{
        width:20px;
        padding: 2px 5px;
    }
    .quantity{
        width: 35px;
    }
    .tab-hide{
        display:none;
    }
}
@media (min-width: 1280px){
    .container{
        max-width: 1250px;
    }
}

/*----------------------------------------- Responsive css End --------------------------------------*/